@import '../../styles/variables.scss';

.FooterModal {
  background: $white;
  border-radius: 9px;
  box-shadow: $boxShadowModal;
  color: $black;
  display: flex;
  flex-flow: row wrap;
  right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100% - 43px);
  z-index: 1;
}

.FooterModal--left {
  width: 200px;
  height: auto;
  bottom: 0;
  top: unset;
  left: 0;
  transform: translateY(-48px);
}