@import '../styles/variables.scss';

.SynonymsBox {
  padding: 9px;
  width: 100%;
  box-sizing: border-box;

  &__header {
    align-items: center;
    display: flex;
    font-size: 11px;
    font-family: SFProText, sans-serif;
    font-weight: 400;
    color: $gray;
  }

  &__header-key {
    color: $black;
    font-weight: 400;
  }

  &__header-key::before {
    content: '\00a0';
  }

  &__synonyms {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__synonyms-item {
    background: $backgroundGray;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: SFProText, sans-serif;
    margin-top: 8px;
    padding: 2px 5px;
    transition: background 0.2s;
    margin-left: 5px;
  }

  &__synonyms-item:hover {
    background: $lightGray;
  }
}