.SingleMood {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 45px;

  &__badge {
    height: 40px;
    position: absolute;
    width: 40px;
  }

  &__score {
    height: 36px;
    position: absolute;
    width: 36px;
  }

  &__emotion {
    height: 30px;
    position: absolute;
    width: 30px;
  }
}
