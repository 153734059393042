@import '../../styles/variables.scss';

.MoodDetectButton {
  background: $white;
  border-radius: 50%;
  box-sizing: content-box;
  height: 20px;
  padding: 4px;
  width: 20px;
  border: 1px solid $lightGray;

  &__toggle {
    background: url("../../assets/images/moods/mood-type-overview.png");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
}

