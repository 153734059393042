@import '../../styles/variables.scss';

.TranslationInput {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    background: $white;
    box-sizing: border-box;
    flex-grow: 1;
    margin-top: 8px;
    overflow-y: scroll;
    padding: 8px;
    position: relative;
    border: solid .5px rgba(36,44,51,.1);
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__input {
    font-family: Satoshi, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    margin: 0;
    border-radius: 10px;
  }

  &__input:empty::before {
    content: attr(data-placeholder);
    color: $gray;
  }

  &__result {
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: $blue;
    margin: 4px 0 0 0;
  }

  &__wrapper::-webkit-scrollbar {
    width: 4px;
  }

  &__wrapper::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 10px;
  }

  &__wrapper::-webkit-scrollbar-thumb:hover {
    background: $darkGray;
  }

  &__footer {
    align-items: center;
    background: $white;
    color: $blue;
    display: flex;
    padding: 8px;
    border: solid .5px rgba(36,44,51,.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__footer-button {
    font-family: Satoshi, sans-serif;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
}