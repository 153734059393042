@import "../../styles/variables.scss";

.MoodData {
  display: flex;
  flex-direction: column;
  padding: 16px 14px;
  background: $backgroundGray;
  border-radius: 9px;

  &__heading {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 14px;
    font-family: Satoshi, sans-serif;
  }

  &__separator {
    border-top: $borderSeparator;
    margin-left: -14px;
    margin-bottom: 14px;
    width: calc(100% + 28px);
  }

  &__emotions {
    display: flex;
    justify-content: center;
    background-color: $white;
    border-radius: 6px;
    padding: 5px 0;
    width: 100%;
    margin-bottom: 4px;
  }

  &__info {
    font-size: 14px;
    margin: 0 0 14px 0;
    font-family: Satoshi, sans-serif;
    font-weight: 500;
  }

  &__loading {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__percentages {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  &__percentages-row {
    background: $white;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 12px 24px;
    font-family: Satoshi, sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
}