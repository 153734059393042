@import './styles/variables.scss';

.App {
  display: flex;
  flex-direction: column-reverse;;
  height: 800px;

  @include fromMobile {
    height: 400px;
    flex-direction: row;
  }

  &__custom-text-area {
    height: 100%;
    width: 100%;

    @include fromMobile {
      width: 50%;
    }

    @include fromTablet {
      width: calc(60%);
    }
  }

  &__error-list {
    height: 100%;
    margin-bottom: 20px;
    padding: 8px 8px 0 8px;
    width: 100%;

    @include fromMobile {
      margin-left: 20px;
      width: 50%;
    }

    @include fromTablet {
      width: calc(40%);
    }
  }
}
