@import '../../styles/variables.scss';

.Translation {
  background: $white;
  border-radius: 50%;
  box-sizing: content-box;
  height: 20px;
  padding: 4px;
  width: 20px;
  border: 1px solid $lightGray;

  &__toggle {
    background: url("../../assets/svgs/flag-icons/Auto.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  &__content {
    background: $backgroundGray;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 16px 14px;
    position: relative;
    width: 100%;
    border-radius: 9px;
  }

  &__content-heading {
    font-size: 14px;
    margin-bottom: 14px;
    font-family: Satoshi, sans-serif;
    font-weight: 700;
  }

  &__content-separator {
    border-top: $borderSeparator;
    margin-left: -14px;
    margin-bottom: 14px;
    width: calc(100% + 28px);
  }

  &__content-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__triangle {
    border-bottom: 10px solid $backgroundGray;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    position: absolute;
    width: 0;
    transform: rotate(180deg) translateY(100%);
    z-index: 10;
  }

  &__triangle--dark {
    border-bottom: 10px solid $backgroundTransparent;
  }
}