@import '../styles/variables.scss';

.CharacterCount {
  font-family: Satoshi, sans-serif;
  border: 1px solid $lightGray;
  font-size: 11px;
  padding: 4px 8px;
  background: white;
  border-radius: 10px;
  line-height: normal;
}

.CharacterCount--negative {
  color: $negative;
  font-weight: 700;
}