@import './src/styles/variables.scss';

.Modal {
  background: $white;
  border-radius: 9px;
  box-shadow: $boxShadowModal;
  position: absolute;
  z-index: 1;

  &__wrapper {
    background: transparent;
    border-radius: 9px;
    color: $black;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    position: relative;
  }

  &__triangle {
    border-bottom: 15px solid $white;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    position: absolute;
    width: 0;
  }

  &__triangle--gray {
    border-bottom: 15px solid $backgroundGray;
  }

  &__triangle-bottom {
    border-left: 12.5px solid transparent;
    border-right: 12.5px solid transparent;
    border-top: 12px solid $white;
    height: 0;
    position: absolute;
    width: 0;
  }

  &__triangle-bottom--gray {
    border-top: 12px solid $backgroundGray;
  }
}

.Modal--gray {
  background: $backgroundGray;
}