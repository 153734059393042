@import './src/styles/variables';

.CopyButton {
  align-items: center;
  background: $white;
  border-radius: 50%;
  border: 1px solid $lightGray;
  display: flex;
  height: 25px;
  justify-content: center;
  padding: 4px;
  transition: width 0.2s linear;
  width: 25px;
  font-family: Satoshi, sans-serif;
  font-size: 12px;
  font-weight: 500;
  box-sizing: content-box;

  &__gray {
    background: $backgroundGray;
    border-radius: 50%;
    box-sizing: border-box;
    height: 29px;
    opacity: 0;
    padding: 2px;
    position: absolute;
    pointer-events: none;
    transform: scale(1);
    transition: opacity 0.2s, transform 0.2s linear;
    width: 29px;
  }

  &__gray--visible {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 1;
    transform: scale(0.85);
  }

  &__loading {
    animation: rotation 1s infinite ease-in-out;
    background: $blue;
    border-radius: 50%;
    box-sizing: border-box;
    height: 25px;
    opacity: 0;
    padding: 2px;
    position: absolute;
    pointer-events: none;
    transition: opacity 0.2s;
    width: 25px;
  }

  &__loading--visible {
    opacity: 1;
  }

  &__copy {
    align-items: center;
    background: $blue;
    border-radius: 18px;
    box-sizing: border-box;
    color: white;
    display: flex;
    height: 100%;
    justify-content: space-between;
    opacity: 0;
    overflow: hidden;
    padding: 5px;
    pointer-events: none;
    transition: opacity 0.2s;
    width: 100%;
  }

  &__copy--visible {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;

    /*Ugly but saves me an additional conditional class name.*/
    & img {
      transform: scale(0.9);
      transition-delay: 0.15s;
      transition: transform 0.2s;
    }
  }

  &__alert {
    background: $violet;
    border-radius: 18px;
    color: white;
    opacity: 0;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-16px, 50%);
    transition: opacity 0.2s;
  }

  &__alert--visible {
    opacity: 1;
  }
}

.CopyButton--extended-en {
  border-radius: 18px;
  width: 63px;
}

.CopyButton--extended-de {
  border-radius: 18px;
  width: 84px;
}