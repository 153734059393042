@import '../../styles/variables';

.Triangle {
  border-bottom: 10px solid $white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  position: absolute;
  width: 0;
  transform: rotate(180deg) translateY(100%);
  z-index: 10;
}

.Triangle--gray {
  border-bottom: 10px solid $backgroundGray;
}

.Triangle--large {
  border-bottom: 15px solid $white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: unset;
}