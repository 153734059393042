@import "./src/styles/variables.scss";

.LanguageSelect {
  &__current {
    align-items: center;
    background: $white;
    border-color: $lightGray;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 38px;
    justify-content: center;
    width: 38px;
  }

  &__image {
    height: 28px;
    width: 28px;
  }
}