@import "./src/styles/variables.scss";

.MoodPreview {
  display: flex;
  flex-direction: column;
  padding: 16px 14px;
  background: $backgroundGray;
  border-radius: 9px;

  &__heading {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 14px;
    font-family: Satoshi, sans-serif;
  }

  &__image {
    background-color: $white;
    background-image: url("../../assets/images/moods/moods-preview.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 35px;
    border-radius: 5px;
    box-sizing: content-box;
    height: 40px;
    margin-bottom: 14px;
    padding: 5px 0;
    width: 100%;
  }

  &__info {
    font-size: 14px;
    margin: 0;
    font-family: Satoshi, sans-serif;
    font-weight: 500;
  }

  &__button {
    background-color: $violet;
    border-radius: 6px;
    border: none;
    box-shadow: $boxShadowViolet;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    margin-top: auto;
    text-align: center;
    width: 100%;
    z-index: 1;
    font-family: Satoshi, sans-serif;
    font-weight: 700;
  }
}