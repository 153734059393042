@import '../../styles/variables';

.ErrorListEmpty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  &__icon {
    height: auto;
    width: 30px;
  }

  &__icon-container {
    align-items: center;
    background: $buttonGreen;
    border-radius: 50%;
    box-shadow: $boxShadowApply 0 4px 14px;;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-bottom: 12px;
    width: 32px;
  }

  &__headline {
    color: $lightBlack;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin: 0 0 4px 0;
  }

  &__text {
    color: $gray;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
    margin: 0;
  }
}