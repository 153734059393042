@import '../styles/variables.scss';

.LanguageSwitch {
  &__toggle {
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid $lightGray;
    background: $white;
    border-radius: 50%;
  }

  &__toggle-img {
    max-width: unset;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &__toggle-auto {
    background: $violet;
    border-radius: 2px;
    color: white;
    font-size: 6px;
    font-weight: 900;
    padding: 1px;
    position: absolute;
    font-family: Satoshi, sans-serif;
    transform: translate(10px, -10px);
    line-height: normal;
    letter-spacing: normal;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__list-item {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: space-between;
    min-width: 160px;
    padding: 0 8px;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__list-item--bordered {
    border-top: $borderSeparator;
  }

  &__triangle {
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    position: absolute;
    width: 0;
    transform: rotate(180deg) translate(-5px, 100%);
    z-index: 10;
  }
}