@import '../../styles/variables.scss';

.LanguageItem {
  border-bottom: $borderSeparator;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 8px 0;
  width: 85%;

  &__image {
    align-items: center;
    display: flex;
  }

  &__image-icon {
    height: 28px;
    width: 28px;
  }

  &__image-icon--selected {
    border-radius: 50%;
    border: 1px solid $violet;
    padding: 1px;
    height: 24px;
    width: 24px;
  }

  &__image-label {
    font-family: Satoshi, sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-left: 4px;
  }

  &__image-label--selected {
    color: $violet;
    font-weight: 700;
  }
}
