@import '../styles/variables.scss';

.ErrorCount {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  border: 1px solid $lightGray;
  margin-left: -1px;

  &__count {
    align-items: center;
    background: $violet;
    border-radius: 50%;
    box-shadow: 0 2.5px 5px 0 rgba($violet, 0.4);
    color: $white;
    cursor: default;
    display: flex;
    font-family: SFProText, sans-serif;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    transition: background 0.1s;
    width: 22px;
    height: 22px;
    line-height: normal;
    letter-spacing: normal;
  }

  &__count--negative {
    background: $negative;
    box-shadow: 0 2.5px 5px 0 rgba($negative, 0.4);
    cursor: pointer;
  }

  &__count--negative:hover {
    background: $negativeDark;
  }

  &__count--positive {
    background: $positive;
    box-shadow: 0 2.5px 5px 0 rgba($positive, 0.4);
  }

  &__modal-text {
    flex-basis: 100%;
    font-family: SFProText, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding: 16px 12px;
    pointer-events: none;
    text-align: center;
  }

  &__modal-button {
    background: transparent;
    border: none;
    border-top: $borderSeparator;
    color: $violet;
    cursor: pointer;
    flex-basis: 50%;
    font-family: SFProText, sans-serif;
    font-size: 11px;
    font-weight: 400;
    padding: 6px 0;
  }

  &__modal-button--apply {
    border-left: $borderSeparator;
    font-weight: 600;
  }

  &__triangle {
    border-bottom: 10px solid $white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    position: absolute;
    width: 0;
    transform: rotate(180deg) translateY(200%);
    z-index: 10;
  }
}
