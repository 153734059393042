@import '../../styles/variables.scss';

.ErrorList {
  background: $backgroundGray;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  &__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex-grow: 1;
  }

  &__list::-webkit-scrollbar {
    display: none;
  }

  &__correction-box--bordered {
    border-bottom: $borderSeparator;
  }

  &__footer {
    border-top: 0.5px solid $white;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    margin: 0 -8px;
    box-sizing: border-box;
  }

  &__footer-left {
    display: flex;
    align-items: center;
  }

  &__footer-right {
    align-items: center;
    display: flex;
  }

  &__footer-left > div {
    margin-right: 5px;

    @include fromDesktop {
      margin-right: 10px;
    }
  }

  &__footer-right > div {
    margin-left: 5px;

    @include fromDesktop {
      margin-left: 10px;
    }
  }
}
