/* Global Styles */

// Font Faces - https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Web_fonts
// WOFF2 - WOFF -> Does not cover various browsers and their versions with release dates from 2009 - 2013.
@font-face {
  font-family: Satoshi;
  font-weight: 300;
  src: url("../assets/fonts/Satoshi-Light.woff2") format("woff2"); // Modern Browsers
  src: url("../assets/fonts/Satoshi-Light.woff") format("woff"); // Old Browsers
}

@font-face {
  font-family: Satoshi;
  font-weight: 400;
  src: url("../assets/fonts/Satoshi-Regular.woff2") format("woff2"); // Modern Browsers
  src: url("../assets/fonts/Satoshi-Regular.woff") format("woff"); // Old Browsers
}

@font-face {
  font-family: Satoshi;
  font-weight: 500;
  src: url("../assets/fonts/Satoshi-Medium.woff2") format("woff2"); // Modern Browsers
  src: url("../assets/fonts/Satoshi-Medium.woff") format("woff"); // Old Browsers<
}

@font-face {
  font-family: Satoshi;
  font-weight: 700;
  src: url("../assets/fonts/Satoshi-Bold.woff2") format("woff2"); // Modern Browsers
  src: url("../assets/fonts/Satoshi-Bold.woff") format("woff"); // Old Browsers
}

@font-face {
  font-family: SFProText;
  font-weight: 400;
  src: url("../assets/fonts/SF-Pro-Text-Regular.woff") format("woff"); // Old Browsers
}

@font-face {
  font-family: SFProText;
  font-weight: 500;
  src: url("../assets/fonts/SF-Pro-Text-Medium.woff") format("woff"); // Old Browsers
}