@import '../styles/variables';

.Toast {
  background: white;
  border-radius: 10px;
  border: $borderSeparator;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 150px;
  opacity: 0;
  padding: 10px 10px;
  position: absolute;
  right: 0;
  text-align: left;
  transform: translate(-10px, -33px);
  transition: transform 0.2s linear, opacity 0.1s linear;
  font-family: Nunito, sans-serif;

  &__close {
    align-self: flex-end;
    background: $buttonGray $buttonCloseIcon no-repeat center;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: 17px;
    padding: 2px;
    width: 17px;
  }
}

.Toast--visible {
  opacity: 1;
  transform: translate(-10px, -43px);
  transition: transform 0.1s linear, opacity 0.1s linear;
}