@import "../styles/variables";

.CorrectionBox {
  background: $white;
  border-radius: 9px;
  box-sizing: border-box;
  padding: 9px;
  width: 100%;

  &__error-type {
    color: $gray;
    font-family: SFProText, sans-serif;
    font-size: 10px;
    font-weight: 400;
  }

  &__suggestion {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    line-height: normal;
  }

  &__text-wrong {
    color: $negative;
    font-family: Satoshi, sans-serif;
    font-size: 11px;
    font-weight: 700;
    text-decoration: line-through;
  }

  &__text-correct {
    font-family: Satoshi, sans-serif;
    font-size: 15px;
    font-weight: 700;
  }

  &__actions {
    align-items: center;
    display: flex;
  }

  &__actions-discard {
    background: $buttonGray $buttonCloseIcon no-repeat center;
    background-size: 7px;
    border: none;
    border-radius: 50%;
    box-shadow: $boxShadowDiscard 0 4px 14px;
    cursor: pointer;
    height: 21px;
    width: 21px;
  }

  &__actions-discard:hover {
    background-color: $buttonGrayActive;
  }

  &__actions-apply {
    background: $buttonGreen $buttonApplyIcon no-repeat center;
    background-size: 7px;
    border: none;
    border-radius: 50%;
    box-shadow: $boxShadowApply 0 4px 14px;
    cursor: pointer;
    height: 21px;
    margin-left: 10px;
    width: 21px;
  }

  &__actions-apply:hover {
    background-color: $buttonGreenActive;
  }
}
