@import "src/styles/variables";

.CustomTextArea {
  background: $backgroundGray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__main {
    flex-grow: 1;
    position: relative;
  }

  &__input {
    font-kerning: none;
    background: $backgroundGray;
    border-radius: 10px;
    border: none;
    box-sizing: border-box;
    font-family: Nunito, sans-serif;
    font-weight: 700;
    font-size: 27px;
    line-height: 1.3em;
    height: 100%;
    outline: none;
    padding: 20px 20px 5px 20px;
    resize: none;
    width: 100%;
    overflow-y: scroll;
    white-space: pre-wrap;
    word-break: break-word;
    letter-spacing: normal;
  }

  &__input::-webkit-scrollbar {
    width: 4px;
  }

  &__input::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 10px;
  }

  &__input::-webkit-scrollbar-thumb:hover {
    background: $darkGray;
  }

  &__input--overlay {
    color: transparent;
    box-sizing: border-box;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    white-space: pre-wrap;
    overflow-y: scroll;
    height: 100%;
    padding-right: 24px;
  }

  &__input--overlay::-webkit-scrollbar {
    display: none;
  }

  &__footer-right-count {
    font-size: 10px;
    background: $white;
    padding: 4px 8px;
    border-radius: 10px;
  }

  &__footer-right-count--red {
    color: $negative;
  }

  &__input-highlight {
    background: $backgroundCorrection;
    display: inline-block;
    font-family: Nunito, sans-serif;
    position: relative;
    cursor: pointer;
    pointer-events: auto;
    border-radius: 2px;
  }

  &__input-highlight--active::before {
    content: "";
    width: 100%;
    height: 3px;
    background: $violet;
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &__input-highlight--synonym {
    background: $backgroundSynonym;
  }

  &__input-highlight--synonym-active:before {
    content: "";
    width: 100%;
    height: 3px;
    background: $positive;
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &__input-highlight--synonym:hover:before {
    background: $positive;
  }
}
