@import "./src/styles/variables.scss";

.LanguageList {
  border-radius: 6px;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &__header {
    display: flex;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    font-weight: 700;
    justify-content: space-between;
    padding: 14px 16px;
  }

  &__wrapper {
    background: $white;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: default;
    height: calc(100% - 26px);
    margin: 0 2px;
    opacity: 0;
    position: absolute;
    top: calc(100%/1.5);
    transition: top 0.2s, opacity 0.2s;
    width: calc(100% - 4px);
  }

  &__wrapper--visible {
    opacity: 1;
    top: 24px;
  }

  &__header-close {
    color: $violet;
    cursor: pointer;
  }

  &__separator {
    border-top: $borderSeparator;
    width: 100%;
  }

  &__list {
    height: calc(100% - 48px);
    margin-right: 4px;
    overflow-y: scroll;
  }

  &__list::-webkit-scrollbar {
    width: 4px;
  }

  &__list::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 10px;
  }

  &__list::-webkit-scrollbar-thumb:hover {
    background: $darkGray;
  }
}

.LanguageList--visible {
  background: $backgroundTransparent;
  pointer-events: auto;
  transition: background 0.4s;
}